import { Box, CircularProgress, Tooltip } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import type { Adress, Intervention } from '@willig/types/api'
import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import type { InterventionCreate } from 'src/types/api/extendedTypes'

import { MeetingModal } from './MeetingModal'

type Props = {
  address: Adress
}

export function MeetingPropositionButton(props: Props) {
  const { address } = props
  const { values } = useFormState<Partial<InterventionCreate>>({
    subscription: { values: true },
  })
  const { search } = useLocation()

  const { adresse_id } = values

  const canAutoOpen = Boolean(search.includes('?adress_id') || adresse_id)
  const disabled = Boolean(!address.lat || !address.lon)

  const modalState = useState<boolean>(false)
  const [_, toggleModal] = modalState
  useEffect(() => {
    if (canAutoOpen && !disabled) {
      toggleModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, canAutoOpen])

  const dataProvider = useDataProvider()
  const { data: defaultSweepId, isLoading: isInterventionLoading } = useQuery<
    unknown,
    unknown,
    string | undefined
  >({
    queryKey: ['getList', 'interventions', address.id],
    queryFn: async () => {
      const { data: interventions } = await dataProvider.getList<Intervention>(
        'Intervention',
        {
          filter: { adresse_id: [address.id] },
          pagination: { page: 0, perPage: 200 },
          sort: { order: 'DESC', field: 'rdv_date' },
        },
        { enabled: Boolean(address.id) },
      )
      return interventions?.[0].sweep_id
    },
    retry: false,
  })

  const { data: defaultValues, isLoading: isDefaultValuesLoading } = useQuery({
    queryKey: ['intervention', 'defaultValues', address.id, defaultSweepId],
    queryFn: () => {
      return {
        sweep_id: defaultSweepId,
        duration: 30,
        lat: address.lat,
        lon: address.lon,
      }
    },
  })

  const isLoading = Boolean(isDefaultValuesLoading || isInterventionLoading)

  if (!address.id) return <></>

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Tooltip
      title={
        disabled ? (
          <div>
            <h4 style={{ margin: 0, fontWeight: 'bold' }}>
              Il manque les coordonnées géographiques sur cette adresse
            </h4>
          </div>
        ) : (
          ''
        )
      }
    >
      <Box>
        <MuiButton
          fullWidth
          color="secondary"
          variant="contained"
          size="small"
          disabled={disabled}
          onClick={() => toggleModal(true)}
        >
          Proposition de rdv
        </MuiButton>
        <MeetingModal modalState={modalState} defaultValues={defaultValues} />
      </Box>
    </Tooltip>
  )
}
