import { Switch, Grid, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import useProfunctorState from '@staltz/use-profunctor-state'
import type {
  FullContactAdresse,
  Intervention,
  Adress,
  Contract,
} from '@willig/types/api'
import { differenceInDays } from 'date-fns'
import queryString from 'query-string'
import { equals, isEmpty } from 'rambda'
import { useCallback, useEffect, useState } from 'react'
import { SaveButton, useNotify, useGetOne } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useGetList } from 'src/libs/useGetList'
import type { InterventionCreate } from 'src/types/api/extendedTypes'
import { theme } from 'src/UI/theme'

import { MeetingPropositionButton } from './FreeSlots/MeetingProposalButton'
import { InvoiceContactInfo } from './interventionFormComponents/InvoiceContactInfo'
import { Relationships } from './interventionFormComponents/Relationships'
import { ResidentInformation } from './interventionFormComponents/ResidentInformation'
import { StaticFields } from './interventionFormComponents/StaticFields'

const useStyles = makeStyles({
  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: ' 1fr 1fr',
    width: '100%',
    columnGap: '2rem',
  },
  suggestedCard: {
    minWidth: 275,
    display: 'flex',
    alignItems: 'flex-end',
  },
  suggestedInfo: {
    flexGrow: 1,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  relationForm: {
    gap: '1rem',
    borderColor: theme.palette.primary.main,
  },
  interventionForm: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  interventionFormButton: {
    margin: '1rem 1.5rem 1rem 0',
  },
  greenContract: {
    backgroundColor: theme.palette.primary.light,
    margin: '1rem 1.5rem 1rem 0',
  },
  yellowContract: {
    backgroundColor: theme.palette.warning.light,
    margin: '1rem 1.5rem 1rem 0',
  },
  redContract: {
    backgroundColor: theme.palette.error.light,
    margin: '1rem 1.5rem 1rem 0',
  },
})

type interventionFieldsProps = {
  isEdit?: boolean
  handleSubmitWithRedirect: any
  saving: any
  searchQuery?: string
  record?: Intervention
}

export type isProState = {
  isPro: boolean
}

export const InterventionFields = (props: interventionFieldsProps) => {
  const {
    isEdit = false,
    saving,
    handleSubmitWithRedirect,
    searchQuery,
    record,
  } = props
  const notify = useNotify()
  const form = useForm() // form used to change values
  const classes = useStyles()
  const { values } = useFormState<Partial<InterventionCreate>>({
    subscription: { values: true },
  }) // current values of every field in the form

  const initialState = { isPro: false }
  const billingState = useProfunctorState<isProState>(initialState)
  const stateProf = billingState.promap(
    (state) => state,
    (value, state) => {
      return { ...state, ...value }
    },
  )

  const errorMessage = getErrorMessages(values, stateProf.state)

  const [search, setSearch] = useState(isEdit) // state of the contact part if search show input if not show suggested

  const [isOtherBillingContact, setIsOtherBillingContact] = useState(
    Boolean(values.facture_contact_id),
  )
  const [searchFacture, setSearchFacture] = useState(isEdit)

  const toggleBillingContact = useCallback(() => {
    setIsOtherBillingContact((otherBillingProps) => {
      if (otherBillingProps) {
        setSearchFacture(false)
        form.change('facture_contact_id', null)
        form.change('facture_adresse_id', null)
      }
      return !otherBillingProps
    })
  }, [form])
  const { data: adressePayor } = useGetList<FullContactAdresse>(
    'FullContactAdresse',

    {
      filter: {
        '!deleted': [1],
        adresse_id: [values.adresse_id],
        is_payor: [1],
      },
    },
    { enabled: Boolean(values.adresse_id) },
  )

  useEffect(() => {
    if (isOtherBillingContact && !isEmpty(adressePayor)) {
      form.change('facture_contact_id', adressePayor[0].contact_id)
    }
    if (adressePayor.length > 1) {
      notify(
        'Erreur  : plusieurs payeurs renseignés, veuillez vérifier dans la base',
        'error',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adressePayor,
    isOtherBillingContact,
    notify,
    toggleBillingContact,
    values.contact_id,
  ])

  useEffect(() => {
    if (!isEmpty(adressePayor)) {
      form.change('facture_contact_id', adressePayor[0].contact_id)
      setIsOtherBillingContact(true)
    }

    if (isEmpty(adressePayor)) {
      form.change('facture_contact_id', null)
      setIsOtherBillingContact(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adressePayor, form])

  useEffect(() => {
    if (searchQuery) {
      const parsedQueries = queryString.parse(searchQuery)
      if (parsedQueries.contact_id === undefined) {
        form.change('adresse_id', parsedQueries.adress_id)
        setSearch(false)
      } else {
        setSearch(true)
        form.change('adresse_id', parsedQueries.adress_id)
        form.change('contact_id', parsedQueries.contact_id)
      }
    }
  }, [form, searchQuery])

  const { data: Address } = useGetOne<Adress>('Adress', values.adresse_id!, {
    enabled: Boolean(values.adresse_id),
  })

  const { data: contract } = useGetList<Contract>(
    'Contract',
    {
      filter: {
        pro_id: Address?.pro_id,
        adresse_id: values.adresse_id,
      },
    },
    {
      enabled: Boolean(values.adresse_id && Address?.pro_id),
    },
  )

  const conditionalStyle = (next_intervention_date?: string) => {
    if (!next_intervention_date) {
      return classes.interventionFormButton
    }
    const dayDiff = differenceInDays(
      new Date(next_intervention_date).getTime(),
      new Date().getTime(),
    )
    if (dayDiff < 0) return classes.redContract
    if (dayDiff >= 0 && dayDiff < 30) return classes.yellowContract
    return classes.greenContract
  }

  const readOnlyContactPayor = Boolean(
    values.facture_contact_id && adressePayor.length > 0,
  )

  return (
    <div className={classes.interventionForm}>
      <Card variant="outlined">
        <CardContent className={classes.cardWrapper}>
          <ResidentInformation
            search={search}
            setSearch={setSearch}
            stateProf={stateProf}
            searchQuery={searchQuery}
          />
        </CardContent>
      </Card>
      {!isEdit && Address && <MeetingPropositionButton address={Address} />}

      <Card variant="outlined">
        <CardContent>
          <Box>
            {readOnlyContactPayor ? (
              <Typography color="error">
                Le contact de facturation ne peut être changé que sur le détail
                de l'adresse d'intervention
              </Typography>
            ) : (
              <>
                <h3>Contact de facturation</h3>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Contact</Grid>
                  <Grid item>
                    <Switch
                      checked={isOtherBillingContact}
                      onChange={toggleBillingContact}
                    />
                  </Grid>
                  <Grid item>Autre contact</Grid>
                </Grid>
              </>
            )}
          </Box>

          {isOtherBillingContact && (
            <div className={classes.cardWrapper}>
              <InvoiceContactInfo
                isDisabled={readOnlyContactPayor}
                state={stateProf}
                setSearchFacture={setSearchFacture}
                searchFacture={searchFacture}
              />
            </div>
          )}
        </CardContent>
      </Card>
      <Relationships
        otherEntriesState={values.otherEntries ?? {}}
        setOtherEntriesState={useCallback(
          (updateFunc) => {
            const prevValue = values.otherEntries ?? {}
            const newValue = updateFunc(prevValue)
            if (!equals(prevValue, newValue)) {
              form.change('otherEntries', newValue)
            }
          },
          [form, values.otherEntries],
        )}
      />
      <StaticFields record={record} />
      <Box
        width="full"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <SaveButton
          label={errorMessage ?? "Enregistrer l'intervention"}
          className={classes.interventionFormButton}
          saving={saving}
          disabled={Boolean(errorMessage || saving)}
          handleSubmitWithRedirect={handleSubmitWithRedirect}
        />
        {!isEdit && Address && Address.pro_id && contract[0] && contract[0].id && (
          <SaveButton
            label={errorMessage ?? "Ajouter l'intervention prévue au contrat"}
            saving={saving}
            className={conditionalStyle(Address?.next_intervention_date)}
            disabled={Boolean(errorMessage || saving)}
            handleSubmitWithRedirect={() => {
              form.change('contract_id', contract[0].id)
              handleSubmitWithRedirect()
            }}
          />
        )}
      </Box>
    </div>
  )
}

function getErrorMessages(values: Partial<InterventionCreate>, state: any) {
  if (!state.isPro && !values.contact_id)
    return 'Contact manquant (intervention non pro)'
  if (state.isPro && !values.facture_contact_id)
    return 'Contact de facturation manquant (intervention pro)'
  if (state.isPro && !values.facture_adresse_id)
    return 'Adresse de facturation manquante (intervention pro)'

  if (values.contact_id && !values.contact_relation_type)
    return 'Relation entre contact et adresse manquant'

  if (!values.adresse_id) return 'Adresse manquante'
  if (Object.values(values.otherEntries ?? {}).some((e) => e === 0))
    return 'Il manque une relation entre Adresse et Contact'
}
