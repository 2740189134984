import type { Theme } from '@material-ui/core'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useMutation } from 'react-query'

import type { FreeSlotResponse } from '../../../libs/interventionQueryBuilder'
import { useInterventionQueryBuilder } from '../../../libs/interventionQueryBuilder'

import { FormContent } from './FormContent'
import { FreeSlotProposal } from './FreeSlotProposal'
import type { ModalFormValues } from './types'

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      position: 'absolute',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
  }
})

type MeetingModalProps = {
  modalState: [boolean, (value: boolean) => void]
  defaultValues?: Partial<ModalFormValues>
}

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: '80%',
    overflow: 'scroll',
  }
}

export function MeetingModal(props: MeetingModalProps) {
  const { modalState, defaultValues } = props
  const [state, toggle] = modalState

  const modalStyle = getModalStyle()

  const { paper } = useStyles()

  function handleClose() {
    toggle(false)
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={state}
      onClose={handleClose}
    >
      <div style={modalStyle} className={paper}>
        <ModalContent onClose={handleClose} defaultValues={defaultValues} />
      </div>
    </Modal>
  )
}

function ModalContent(
  props: Pick<MeetingModalProps, 'defaultValues'> & {
    onClose: () => void
  },
) {
  const { defaultValues } = props

  const methods = useForm<ModalFormValues>({
    defaultValues,
  })

  const { control, handleSubmit, watch } = methods

  const queryBuilder = useInterventionQueryBuilder()

  const { mutateAsync, isLoading } = useMutation(queryBuilder.getFreeSlots())

  const [freeSlots, setFreeSlots] = useState<FreeSlotResponse[]>()

  async function submitHandler(values: ModalFormValues) {
    const response = await mutateAsync(values)
    setFreeSlots(response)
  }

  const duration = watch('duration')
  const sweepId = watch('sweep_id')

  useEffect(() => {
    submitHandler(methods.getValues())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, sweepId])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Box
          mt={2}
          display="flex"
          width="100%"
          gridGap={16}
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginBottom: 20 }}
          >
            Proposition de rendez-vous
          </Typography>
          <FormContent control={control} />

          <MuiButton
            fullWidth
            color="primary"
            variant="contained"
            size="small"
            type="submit"
          >
            Proposer rdv
          </MuiButton>
        </Box>
      </form>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <FreeSlotProposal freeSlots={freeSlots} onClose={props.onClose} />
      )}
    </FormProvider>
  )
}
